import React, { useEffect } from "react";
import Routes from "./routes/Routes";
import { createTheme, ThemeProvider } from "@mui/material";
import { lightModePalette, myBreakpoints, typographyProperties } from "./helpers/constant/themeConstant";
import { setCookie } from "./helpers/Cookie";
import ErrorBoundary from "./common/ErrorBoundary/ErrorBoundary";

function App({ history }) {
  const theme = React.useMemo(
    () =>
      createTheme({
        palette: lightModePalette,
        typography: typographyProperties,
        breakpoints: myBreakpoints,
      }),
    []
  );

  if (process.env.REACT_APP_PRODUCTION_MODE !== "true") {
    // TODO set api access token in cookie for test
    // setCookie(process.env.REACT_APP_ACCESS_TOKEN, "QW4RFEW1G1HRE1HH1E", process.env.REACT_APP_ACCESS_TOKEN_VALIDITY);
    setCookie(process.env.REACT_APP_ACCESS_TOKEN, "DGM2RNXDL8KS85JD066E", process.env.REACT_APP_ACCESS_TOKEN_VALIDITY);
  }

  useEffect(() => {
    if (window.setCollapsedMenu) {
      window.setCollapsedMenu(true);
    }
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <ErrorBoundary>
        <Routes history={history} />
      </ErrorBoundary>
    </ThemeProvider>
  );
}

export default App;
