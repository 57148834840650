import React from "react";
import { Avatar, Grid, Paper, Stack, Typography } from "@mui/material";

const AnalyticsDataCard = ({ fullWidth = false, icon, count, title }) => {
  return (
    <Grid item xs={fullWidth ? 12 : 6} md={12}>
      <Paper elevation={0}>
        <Stack direction={"row"} alignItems={"center"} spacing={2} sx={{ padding: { xs: "8px 16px", md: "16px" } }}>
          <Avatar variant={"rounded"} sx={{ bgcolor: "other.secondaryHover", borderRadius: "0 4px 4px 0" }}>
            {icon}
          </Avatar>
          <Stack direction={"column"}>
            <Typography variant='h5' sx={{ width: "max-content" }}>
              {count}
            </Typography>
            <Typography color='text.secondary' variant='body2'>
              {title}
            </Typography>
          </Stack>
        </Stack>
      </Paper>
    </Grid>
  );
};

export default AnalyticsDataCard;
