import React from "react";
import { Box, Stack, Typography } from "@mui/material";
import { Add } from "@mui/icons-material";
import SettingsIcon from "@mui/icons-material/Settings";
import AddOrEditDialerList from "../DialerList/AddOrEditDialerList";
import BasicModalWithHeader from "../../common/Modal/BasicModalWithHeader";
import CoreButton from "../../common/Button/CoreButton";
import { Buttons, Texts } from "../../helpers/constant/Constants";
import useBoolean from "../../hooks/useBoolean";
import BasicDrawer from "../../common/Drawer/BasicDrawer";
import DefaultDialerListSettings from "../DialerListSettings/DefaultDialerListSettings";
import AddNewGeneralScriptAndDefaultScript from "../GeneralScript/AddNewGeneralScriptAndDefaultScript";

const PowerDialerHeader = () => {
  const { value: isShowModal, setTrue: setShowModal, setFalse: setCloseModal } = useBoolean(false);
  const {
    value: isShowDefaultDrawer,
    setTrue: setShowDefaultDrawer,
    setFalse: setCloseDefaultDrawer,
  } = useBoolean(false);
  const {
    value: isShowCreateScriptModal,
    setTrue: setShowCreateScriptModal,
    setFalse: setCloseCreateScriptModal,
  } = useBoolean(false);

  return (
    <Stack direction='row' justifyContent='space-between' alignItems='center' spacing={2} mb={2}>
      <Box>
        <Typography variant={"h4"} color={"text.primary"}>
          {Texts.mainHeader}
        </Typography>
        <Typography variant={"body2"} color={"text.secondary"}>
          {Texts.subHeader}
        </Typography>
      </Box>

      <Stack direction={"row"} alignItems={"center"} spacing={2}>
        <CoreButton
          variant='outlined'
          size='large'
          color={"secondary"}
          startIcon={<SettingsIcon />}
          onClick={setShowDefaultDrawer}
          sx={{ minWidth: "max-content" }}
        >
          {Buttons.defaultSettings}
        </CoreButton>
        <CoreButton
          variant='contained'
          size='large'
          color={"secondary"}
          startIcon={<Add />}
          onClick={setShowModal}
          sx={{ minWidth: "max-content" }}
        >
          {Buttons.addNewPowerList}
        </CoreButton>
      </Stack>

      <BasicModalWithHeader title={Texts.createDialerListTitle} open={isShowModal} onClose={setCloseModal}>
        <AddOrEditDialerList onClose={setCloseModal} isEditMode={false} />
      </BasicModalWithHeader>
      <BasicDrawer open={isShowDefaultDrawer} toggleDrawer={setCloseDefaultDrawer} title={Texts.defaultSettings}>
        <DefaultDialerListSettings openGeneralScript={setShowCreateScriptModal} />
      </BasicDrawer>
      <BasicDrawer open={isShowCreateScriptModal} toggleDrawer={setCloseCreateScriptModal} title={"Add New Script"}>
        <AddNewGeneralScriptAndDefaultScript onCancel={setCloseCreateScriptModal} />
      </BasicDrawer>
    </Stack>
  );
};
export default PowerDialerHeader;
