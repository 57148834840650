import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, Divider, Paper, Stack, Typography } from "@mui/material";
import CallSettingDefaultTab from "./CallSettings/CallSettingDefaultTab";
import { selectDefaultCallSetting } from "../../state/features/listDefaultSetting/defaultListSettingSelector";
import {
  getScriptForDefaultSetting,
  hasMoreGeneralScripts,
  toggleGeneralScriptPreview,
} from "../../state/features/listDefaultSetting/defaultCallSetting/defaultCallSettingSlice";
// import CallScriptForDefaultSetting from "./DefaultListSettingOptions/CallScriptForDefaultSetting";
import GeneralScriptIndex from "./DefaultListSettingOptions/GeneralScriptIndex";
import DynamicScriptList from "../GeneralScript/GeneralScriptList";
import CoreButton from "../../common/Button/CoreButton";
import CustomTabs, { CustomTab } from "../../common/Tab/CustomTabs";
import VoiceMailSetting from "./VoiceMailSetting";

const DefaultDialerListSettings = ({ openGeneralScript }) => {
  const dispatch = useDispatch();
  const {
    generalScripts: { isShowing: generalScriptPreview, hasMore, isLoading, perPage, page, search },
    callScripts: { isGetLoading, scripts: callScripts },
  } = useSelector(selectDefaultCallSetting);

  useEffect(() => {
    getCallScript();
  }, []);

  const getCallScript = () => {
    dispatch(getScriptForDefaultSetting({ page: 1, limit: 50 }));
  };

  const renderEachCallScript = () => {
    // const view = [];
    // scriptList.forEach((item, index) => {
    //   view.push(<CallScriptForDefaultSetting key={index} index={index} item={item} />);
    // });
    // return view;

    return <DynamicScriptList scripts={callScripts} preview={true} />;
  };

  const renderCallScriptPreview = () => {
    if (isGetLoading) {
      return (
        <Paper
          elevation={0}
          sx={{ bgcolor: "action.actionHover", overflow: "hidden", mb: 2, mx: "auto", width: "80%" }}
        >
          <Stack alignItems={"center"} justifyContent={"center"} py={3}>
            <Typography variant={"body2"} color={"text.primary"}>
              Loading... Please wait
            </Typography>
          </Stack>
        </Paper>
      );
    }

    if (callScripts.length === 0) {
      return (
        <Paper
          elevation={0}
          sx={{ bgcolor: "action.actionHover", overflow: "hidden", mb: 2, mx: "auto", width: "80%" }}
        >
          <Stack alignItems={"center"} justifyContent={"center"} py={3}>
            <Typography variant={"body2"} color={"text.primary"}>
              No scripts Found
            </Typography>
          </Stack>
        </Paper>
      );
    }
    return <div>{renderEachCallScript()}</div>;
  };

  const handleScroll = (e) => {
    console.log("hasMore: " + hasMore, "isLoading: " + isLoading);
    if (
      hasMore &&
      !isLoading &&
      Math.round(e.target.scrollTop + e.target.clientHeight, 10) >= Math.round(e.target.scrollHeight, 10)
    ) {
      console.log("2nd");

      dispatch(
        hasMoreGeneralScripts({
          limit: perPage,
          page: page,
          search: search,
        })
      );
    }
  };

  const renderAddScript = () => {
    if (generalScriptPreview) {
      return (
        <>
          <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"} px={4} py={2}>
            <Stack direction={"column"}>
              <Typography variant='body2medium' color={"text.primary"}>
                General Scripts
              </Typography>
              <Typography color='text.secondary' variant='chipLight'>
                Choose a call script for ongoing connected call
              </Typography>
            </Stack>

            <CoreButton
              variant='outlined'
              size='large'
              color={"secondary"}
              onClick={openGeneralScript}
              sx={{ minWidth: "max-content" }}
            >
              Create script
            </CoreButton>
          </Stack>
          <Box>
            <GeneralScriptIndex handleOnScroll={(e) => handleScroll(e)} refreshCallScript={getCallScript} />
          </Box>
        </>
      );
    } else {
      return (
        <>
          <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"} px={4} py={2}>
            <Stack direction={"column"}>
              <Typography variant='body2medium' color={"text.primary"}>
                Call Script
              </Typography>
              <Typography color='text.secondary' variant='chipLight'>
                Choose a call script for ongoing connected call
              </Typography>
            </Stack>

            <CoreButton
              variant='outlined'
              size='large'
              color={"secondary"}
              onClick={() => dispatch(toggleGeneralScriptPreview(true))}
              sx={{ minWidth: "max-content" }}
            >
              Add script
            </CoreButton>
          </Stack>
          <Box px={4} py={2}>
            {renderCallScriptPreview()}
          </Box>
        </>
      );
    }
  };

  const renderView = () => {
    // if (!generalScriptPreview) {
    //   return (
    //     <CustomTabs>
    //       <CustomTab title={"Call Settings"}>
    //         <>
    //           <CallSettingDefaultTab />
    //           <Divider />
    //         </>
    //       </CustomTab>
    //       <CustomTab title={"Script"}>
    //         <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"} px={4} py={2}>
    //           <Stack direction={"column"}>
    //             <Typography variant='body2medium' color={"text.primary"}>
    //               Call Script
    //             </Typography>
    //             <Typography color='text.secondary' variant='chipLight'>
    //               Choose a call script for ongoing connected call
    //             </Typography>
    //           </Stack>

    //           <CoreButton
    //             variant='outlined'
    //             size='large'
    //             color={"secondary"}
    //             onClick={() => dispatch(toggleGeneralScriptPreview(true))}
    //             sx={{ minWidth: "max-content" }}
    //           >
    //             Add script
    //           </CoreButton>
    //         </Stack>
    //         <Box px={4} py={2}>
    //           {renderCallScriptPreview()}
    //         </Box>
    //       </CustomTab>
    //     </CustomTabs>
    //   );
    // }

    return (
      <CustomTabs>
        <CustomTab title={"Call Settings"}>
          <>
            <CallSettingDefaultTab />
            <Divider />
          </>
        </CustomTab>
        <CustomTab title={"Voice Mail"}>
          <>
            <VoiceMailSetting />
          </>
        </CustomTab>
        <CustomTab title={"Script"}>
          {renderAddScript()}
          {/* {generalScriptPreview ? (
            <GeneralScriptIndex refreshCallScript={getCallScript} />
          ) : (
            <>
              <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"} px={4} py={2}>
                <Stack direction={"column"}>
                  <Typography variant='body2medium' color={"text.primary"}>
                    Call Script
                  </Typography>
                  <Typography color='text.secondary' variant='chipLight'>
                    Choose a call script for ongoing connected call
                  </Typography>
                </Stack>

                <CoreButton
                  variant='outlined'
                  size='large'
                  color={"secondary"}
                  onClick={() => dispatch(toggleGeneralScriptPreview(true))}
                  sx={{ minWidth: "max-content" }}
                >
                  Add script
                </CoreButton>
              </Stack>
              <Box px={4} py={2}>
                {renderCallScriptPreview()}
              </Box>
            </>
          )} */}
        </CustomTab>
      </CustomTabs>
    );
  };

  return (
    <Box sx={{ height: "calc(100% - 74px)", overflowY: "scroll", "&::-webkit-scrollbar": { display: "none" } }}>
      {renderView()}
    </Box>
  );
};
export default DefaultDialerListSettings;
