import {
  AddUserIcon,
  AnalyticIcon,
  ApiIcon,
  CopyIcon,
  DeleteIcon,
  EditIcon,
  SettingIcon,
  UploadIcon,
} from "../../common/icon/Icons";

export const Texts = {
  mainHeader: "My Power Dialer Lists",
  subHeader: "Now you can see your all power dialer lists & contacts here",
  leftSideTitle: "My Lists",
  emptyPowerList: "No list created yet !s",
  rightSideHeader: "Power Dialer List 2",
  rightSideSubHeader: "Contacts",
  searchInput: "Search",
  filterText: "Recent",
  analytic: "Analytics",
  analyticTitle: "Active Time Breakdown",
  totalContact: "Total Contacts",
  callReceived: "Call Received",
  callDisconnect: "Call Disconnected",
  estimatedSession: "Estimated Session Time",
  dialingSession: "Dialling Sessions",
  ringing: "Ringing",
  outgoingCall: "Ongoing Call",
  waiting: "Waiting",
  startDate: "Start Date",
  lastDialed: "Last Dialed",
  contactListEmpty: "Power Dialer List is empty",
  contactListEmptyHints: "You can start adding contacts to fill up this list",
  rowPerPage: "Rows per page:",
  pause: "Pause",
  play: "Play",
  addContactFromList: "Add from contact list",
  tags: "Tags",
  clear: "Clear",
  campaigns: "Campaigns",
  states: "States",
  sort: "Sort By",
  cancel: "Cancel",
  createDialerListTitle: "Create New Power Dialer List",
  listName: "List Name",
  vnSelectFromCDL: "Which number to call from?",
  description: "Description",
  copyDLSetting: "Copy Settings from",
  color: "Color",
  settings: "Settings",
  defaultSettings: "Default Settings",
  numberOfCall: "Number of calls at a time",
  numberOfCallHints: "At a time, you can call single or multiple lines",
  ringTime: "Ring time",
  ringTimeHints: "Set the duration of your call ringing",
  maxCallTime: "Maximum call time",
  maxCallTimeHints: "Set the max duration of your call time",
  virtualNumberSelect: "Select Virtual Number",
  virtualNumberSelectHints: "You need to select virtual numbers",
  transferCalls: "Transfer Calls",
  transferCallsHints: "You can transfer calls to other numbers",
  callRecording: "Call Recording",
  callRecordingHints: "You can record a power dialer session",
  actionForIncomingCall: "What will happen to an incoming call when the power dialer is going on?",
  actionForIncomingCallHints: "You can control how you want to interact",
  actionForMoveNextCall: "How do you want to move to the next call?",
  actionForMoveNextCallHints: "You can control how you want to move to the next call",
  actionForDisconnectCall: "What will happen if the call is not picked or gets disconnected",
  actionForDisconnectCallHints: "You can control how you want to interact",
  sendSMS: "Send an SMS",
  sendSMSHintsTrigger: "You can send an SMS as trigger",
  sendEmail: "Send an Email",
  sendEmailHintsTrigger: "You can send an email as trigger",
  sendVoice: "Send a Ringless Voicemail",
  sendVoiceHintsTrigger: "You can send a voice mail as trigger",
  selectVoiceMessage: "Select a pre-recorded message",
  noVoiceMessage: "You haven't selected a message yet",
  addToCampaignTrigger: "Add to a campaign",
  addToCampaignTriggerHints: "You can add the contact to a campaign",
  addTagTrigger: "Add a tag",
  addTagTriggerHints: "You can add a tag to the contact",
  addToListTrigger: "Add to another list",
  addToListTriggerHints: "You can add the contact to another power dialer list",
  intervalBetCalls: "Interval between calls",
  durationBetCalls: "You can set the duration between calls",
  writeScript: "Write Script",
  termAndCondition: "Terms and Conditions",
  settingOverview: "Settings Overview",
  resumeCall: "Resume Power Dialer",
};
export const Buttons = {
  addNewList: "Create New List",
  addNew: "Add New",
  addNewPowerList: "New Power Dialer List",
  defaultSettings: "Default Settings",
  addContacts: "Add Contacts",
  startDialer: "Start Power Dialer",
  addContactFromList: "Add from contact list",
  importContact: "Import Contacts",
  useApi: "Use API",
  loadMore: "Load More ...",
  saveChanges: "Save changes",
  saveList: "Save List",
  add: "Add",
  addToTheList: "Add to the list",
  change: "Change",
};
export const Tooltips = {
  graphView: "Analytics",
  filter: "Filter",
  settingView: "Settings",
};
export const PlaceholderText = {
  listName: "List name here",
  writeHere: "Write here...",
};
export const DialerListMenu = [
  { title: "Edit", value: "edit", icon: <EditIcon /> },
  {
    title: "Settings",
    value: "settings",
    icon: <SettingIcon height='20' width='20' fill='#949DB2' />,
  },
  { title: "View Analytics", value: "analytics", icon: <AnalyticIcon /> },
  { title: "Duplicate", value: "duplicate", icon: <CopyIcon /> },
  { title: "Delete", value: "delete", icon: <DeleteIcon /> },
];
export const ColorList = [
  { value: "#6258FF" },
  { value: "#006DF5" },
  { value: "#ED0039" },
  { value: "#F2994A" },
  { value: "#A352C2" },
  { value: "#008945" },
  { value: "#FFB400" },
  { value: "#E0E0E0" },
  { value: "#D75D44" },
  { value: "#513C86" },
];
export const AddContactActionMenu = [
  {
    value: "add_from_contact_list",
    label: "Add from contact list lo",
    icon: <AddUserIcon />,
  },
  { value: "import_contacts", label: "Import contacts", icon: <UploadIcon /> },
  { value: "use_api", label: "Use API", icon: <ApiIcon /> },
];
export const numberOfCallSettingOption = [
  { value: "1", title: "Single" },
  { value: "3", title: "3 Lines" },
  { value: "5", title: "5 Lines" },
  { value: "10", title: "10 Lines" },
];
export const ringTimeSettingOption = [
  { value: "10", title: "10s" },
  { value: "20", title: "20s" },
  { value: "30", title: "30s" },
  { value: "60", title: "60s" },
];
export const maxCallTimeSettingOption = [
  { value: "5", title: "5min" },
  { value: "10", title: "10min" },
  { value: "15", title: "15min" },
  { value: "240", title: "No Limit" },
];
export const ContactSelectActionMenu = [
  { value: "__all_", label: "All" },
  { value: "f_100", label: "First 100" },
  { value: "f_500", label: "First 500" },
  { value: "f_1000", label: "First 1000" },
  { value: "l_1000", label: "Next 100" },
  { value: "l_500", label: "Next 500" },
  { value: "l_1000", label: "Next 1000" },
];
export const ActionForIncomingCallOptions = [
  { value: "1", label: "Forward the call" },
  { value: "2", label: "Play a pre-recorded message" },
  { value: "3", label: "Do nothing" },
];

export const ActionForCallTimeExceedOptions = [
  { value: "1", label: "Show warning when the maximum call time is exceeded." },
  { value: "2", label: "Call is disconnected when the maximum call time is exceeded." },
];

export const ActionForMoveNextCallOptions = [
  { value: "1", label: "Automatically" },
  { value: "2", label: "Manually by clicking a button" },
];
export const TimelineContentTypes = {
  all: "__all__",
  sms: 1,
  mms: 2,
  voice: 3,
  email: 4,
  call: 5,
  callRecording: 6,
  note: 16,
  script: "_script_",
  directMail: "__direct_mail__",
  videoMail: 14,
  postcard: 21,
  letter: 24,
  greetingCard: 22,
  gift: 23,
};
export const ConversationTabs = [
  {
    id: TimelineContentTypes.all,
    title: "All",
    isShow: true,
  },
  {
    id: TimelineContentTypes.sms,
    title: "Texts",
    isShow: true,
  },
  {
    id: TimelineContentTypes.email,
    title: "Emails",
    isShow: true,
  },
  {
    id: TimelineContentTypes.videoMail,
    title: "Video Emails",
    isShow: true,
  },
  {
    id: TimelineContentTypes.voice,
    title: "Ringless Vmails",
    isShow: true,
  },
  {
    id: TimelineContentTypes.directMail,
    title: "Direct Mail/Gifts",
    isShow: true,
  },
  {
    id: TimelineContentTypes.call,
    title: "Calls",
    isShow: true,
  },
  {
    id: TimelineContentTypes.callRecording,
    title: "Call Recordings",
    isShow: true,
  },
  {
    id: TimelineContentTypes.script,
    title: "Script",
    isShow: false,
  },
];

export const TimelineInOutTypes = {
  in: 1,
  out: 2,
};
export const TimelineReadTypes = {
  read: 1,
  unread: 0,
};
export const TimelineStatus = {
  pending: 0,
  success: 1,
  fail: 2,
  sent: 3,
  undelivered: 4,
  accepted: 5,
  deleted: 6,
};
export const CallStartType = [
  { title: "Resume from where you left", value: 1 },
  { title: "Start from beginning of the list", value: 2 },
];

export const ADD_TAG_FOR = {
  TRIGGER: "trigger",
};

export const ATTACHMENT_TYPE = {
  SMS: "SMS",
  EMAIL: "EMAIL",
};

export const ATTACHMENT_LOADING_KEY = {
  send_sms_trigger_attachment: "isLoadingSmsAttachment",
  send_email_trigger_attachment: "isLoadingEmailAttachment",
};

export const SCRIPT_SETTING_PERSONALIZED_TAGS = [
  {
    title: "Contact (14)",
    tags: [
      {
        title: "FIRST NAME",
        value: "[[first_name]]",
      },
      {
        title: "LAST NAME",
        value: "[[last_name]]",
      },
      {
        title: "CONTACT EMAIL",
        value: "[[email]]",
      },
      {
        title: "Contact Phone Number",
        value: "[[contact_phone_number]]",
      },
      {
        title: "Property Country",
        value: "[[contact_country]]",
      },
      {
        title: "Property State",
        value: "[[contact_state]]",
      },
      {
        title: "Contact City",
        value: "[[contact_city]]",
      },
      {
        title: "Property Street Address",
        value: "[[contact_street_address]]",
      },
      {
        title: "Contact Company Name",
        value: "[[contact_company_name]]",
      },
      {
        title: "Property URL",
        value: "[[contact_url]]",
      },
      {
        title: "Property Zip Code",
        value: "[[contact_zip_code]]",
      },
      {
        title: "Contact Deal Value",
        value: "[[contact_deal_value]]",
      },
      {
        title: "Contact Birth Date",
        value: "[[contact_birth_date]]",
      },
      {
        title: "Contact Anniversary Date",
        value: "[[contact_anniversary_date]]",
      },
    ],
  },
  {
    title: "User (3)",
    tags: [
      {
        title: "My Name",
        value: "[[my_name]]",
      },
      {
        title: "My Phone",
        value: "[[my_phone]]",
      },
      {
        title: "My Email",
        value: "[[my_email]]",
      },
    ],
  },
];

export const TIME_LIMIT_CLOSE_CALL = {
  disconnect: 2,
  showAlert: 1,
};
