import React from "react";
import { useSelector } from "react-redux";
import { Grid, Skeleton } from "@mui/material";
import PeopleIcon from "@mui/icons-material/People";
import PhoneCallbackIcon from "@mui/icons-material/PhoneCallback";
import PhoneDisabledIcon from "@mui/icons-material/PhoneDisabled";
import PhoneInTalkIcon from "@mui/icons-material/PhoneInTalk";
import TimelapseIcon from "@mui/icons-material/Timelapse";
import AnalyticsDataCard from "./AnalyticsDataCard";
import { selectDialerAnalyticsState } from "../../state/features/dialerAnalytics/dialerAnalyticsSelector";

const AnalyticReportLeft = () => {
  const { data, isLoading } = useSelector(selectDialerAnalyticsState);

  const {
    totalContacts = 0,
    totalCallReceived = 0,
    totalCallDisconnect = 0,
    totalEstimateSessionTime = 0,
    totalDialingSession = 0,
  } = data || {};

  return (
    <Grid container rowSpacing={2} columnSpacing={{ xs: 2, md: 1 }} sx={{ minWidth: "285px" }}>
      <AnalyticsDataCard
        icon={<PeopleIcon color={"secondary"} />}
        count={isLoading ? <Skeleton variant={"text"} width={40} /> : totalContacts}
        title={"Total Contacts"}
      />
      <AnalyticsDataCard
        icon={<PhoneCallbackIcon color={"secondary"} />}
        count={isLoading ? <Skeleton variant={"text"} width={40} /> : totalCallReceived}
        title={"Call Received"}
      />
      <AnalyticsDataCard
        icon={<PhoneDisabledIcon color={"secondary"} />}
        count={isLoading ? <Skeleton variant={"text"} width={40} /> : totalCallDisconnect}
        title={"Call Disconnected"}
      />
      <AnalyticsDataCard
        icon={<PhoneInTalkIcon color={"secondary"} />}
        count={isLoading ? <Skeleton variant={"text"} width={40} /> : totalDialingSession}
        title={"Dialling Sessions"}
      />
      <AnalyticsDataCard
        icon={<TimelapseIcon color={"secondary"} />}
        count={isLoading ? <Skeleton variant={"text"} width={40} /> : totalEstimateSessionTime}
        title={"Total Session Time"}
        fullWidth={true}
      />
    </Grid>
  );
};

export default AnalyticReportLeft;
