const secondsToTime = function (sec) {
  let x = !isNaN(sec) ? Number(sec) : 0;

  const seconds = Math.round(x % 60);
  x /= 60;
  const minutes = Math.round(x % 60);
  x /= 60;
  const hours = Math.round(x % 24);
  x /= 24;
  const days = Math.round(x);

  let formattedTime = "";

  if (days > 0) formattedTime += `${days}day `;
  if (hours > 0) formattedTime += `${hours}h`;
  if (minutes > 0) formattedTime += `${minutes}min `;
  formattedTime += `${seconds}sec`;

  return formattedTime;
};

export default secondsToTime;
