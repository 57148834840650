import React from "react";
import { useSelector } from "react-redux";
import { Cell, Pie, PieChart } from "recharts";
import { Box, Skeleton, Stack, styled, Typography } from "@mui/material";
import { selectDialerAnalyticsState } from "../../state/features/dialerAnalytics/dialerAnalyticsSelector";

const TotalDuration = styled(Stack)(() => ({
  position: "absolute",
  top: "calc(50% - 5px)",
  left: "4px",
  textAlign: "center",
  width: "100%",
}));

const ChartIdentifierColor = styled(Box)(({ backgroundColor }) => ({
  backgroundColor: backgroundColor,
  width: "12px",
  height: "12px",
  borderRadius: "50%",
}));

const COLORS = ["#0088FE", "#00C49F", "#FFBB28"];

const ReportContentList = ({ color = "", name, duration }) => {
  return (
    <Stack direction={"row"} alignItems={"baseline"} justifyContent={"center"} spacing={1} sx={{ width: "130px" }}>
      <ChartIdentifierColor backgroundColor={color} />
      <div>
        <Typography variant={"body1medium"}>{name}</Typography>
        <Typography variant={"body1"} color={"text.secondary"}>
          {duration}
        </Typography>
      </div>
    </Stack>
  );
};

const AnalyticsPieChart = () => {
  const { data: analyticReportData, isLoading } = useSelector(selectDialerAnalyticsState);
  const {
    totalRingingTime,
    // totalOnGoingCall,
    // totalWaitingTime,
    totalOutBoundDuration,
    // totalRingingTimeBySec,
    // totalOnGoingCallBySec,
    // totalWaitingTimeBySec,
    totalOutBoundDurationBySec,
  } = analyticReportData || {};

  // const totalTimeBySec = totalRingingTimeBySec + totalOnGoingCallBySec + totalWaitingTimeBySec;

  const pieChartData = [
    { name: "Ringing", value: totalOutBoundDurationBySec || 1, duration: totalOutBoundDurationBySec },
    // { name: "Ringing", value: totalTimeBySec === 0 ? 1 : totalRingingTimeBySec, duration: totalRingingTime },
    // { name: "Ongoing Call", value: totalTimeBySec === 0 ? 1 : totalOnGoingCallBySec, duration: totalOnGoingCall },
    // { name: "Waiting", value: totalTimeBySec === 0 ? 1 : totalWaitingTimeBySec, duration: totalWaitingTime },
  ];

  return (
    <Stack direction={"column-reverse"} alignItems={"center"} spacing={1} justifyContent={"center"}>
      <Box sx={{ position: "relative" }}>
        <PieChart width={400} height={400}>
          <Pie
            data={pieChartData}
            cx={200}
            cy={200}
            innerRadius={100}
            outerRadius={120}
            fill='#8884d8'
            paddingAngle={2}
            dataKey='value'
          >
            {pieChartData.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
            ))}
          </Pie>
        </PieChart>
        <TotalDuration alignItems={"center"} justifyContent={"center"}>
          <Typography variant={"body2medium"} color={"text.secondary"}>
            Total Outbound Duration
          </Typography>
          <Typography variant={"h6"} color={"text.primary"} sx={{ fontSize: "16px" }}>
            {totalOutBoundDuration}
          </Typography>
        </TotalDuration>
      </Box>

      <Stack direction={"row"} alignItems={"center"} justifyContent={"center"} spacing={2}>
        <ReportContentList
          color={COLORS[0]}
          name={"Ringing"}
          duration={isLoading ? <Skeleton variant={"text"} width={48} /> : totalRingingTime}
        />
        {/*<Divider orientation='vertical' variant='middle' flexItem sx={{ height: "25px", alignSelf: "center" }} />*/}
        {/*<ReportContentList*/}
        {/*  color={COLORS[1]}*/}
        {/*  name={"Ongoing Call"}*/}
        {/*  duration={isLoading ? <Skeleton variant={"text"} width={80} /> : totalOnGoingCall}*/}
        {/*/>*/}
      </Stack>
    </Stack>
  );
};

export default AnalyticsPieChart;
